import React, { useEffect, useState } from "react"
import cx from "classnames"
import { graphql, useStaticQuery, Link } from "gatsby"
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { IMG } from "../const"
import { EXPERTISE, LEVELS, TITLES } from "../data/project-support"
import "../assets/styles/commons/_common-kit.scss"
import * as styles from "../assets/styles/project-support.module.scss"
import PortfolioImagesContainer from "../components/PortfolioImagesContainer"
import PackageCard from "../components/package-card"
import Headline from "../components/headline"

const TitleBlock = loadable(() => import("../components/title-block"))
const ContactForm = loadable(() => import("../components/contact-form"))

const ProjectSupport = () => {
  const data = useStaticQuery(graphql`
    query {
      portfolioJson {
        name
        projects {
          title
          description
          metaTitle
          tagList
          country
          flagIconUrl
          imageLink
          mainBgImage
          mainPortfolioImage
          pageList
          externalLink
          slug
          testimonial {
            avatar
            paragraph
            name
            job
          }
        }
      }
    }
  `)

  const [projectsList, setProjectsList] = useState([])

  useEffect(() => {
    let list = []

    data.portfolioJson.projects.map(
      item =>
        item.pageList?.length > 0 &&
        item.pageList.map(page => page === "project-support" && list.push(item))
    )

    setProjectsList(list)
  }, [data.portfolioJson.projects])

  return (
    <Layout
      pageTitle="System Support"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-project-support.jpg`}
    >
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={cx(styles.projectSupportBlock, "common-kit")}>
            <Helmet>
              <script src="/avif-webp.min.js" />
            </Helmet>
            <article>
              <Headline>
                <div className={cx(styles.headline, "headline")}>
                  <div className={cx(styles.headline_grid, "headline_grid")}>
                    <p className="strongText">we can help you with</p>
                    <h1 className="withIndent">
                      <strong>System</strong> support
                    </h1>
                    <div className="rightColumn">
                      <p>
                        Every project is different and requires a unique level
                        of support, but every software needs support to some
                        extent.
                      </p>
                      <div>
                        <Link
                          to="#contact-form"
                          className="goldFatLink smallText support-secure"
                        >
                          Secure Your Software Success
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Headline>
              {/* ------- Support terms ------- */}
              <section className={cx(styles.terms, "section")}>
                <div>
                  <div className={styles.terms__image}>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
													${IMG.PROJECT_SUPPORT}/support_480.avif,
													${IMG.PROJECT_SUPPORT}/support_960.avif 2x,
													${IMG.PROJECT_SUPPORT}/support_1440.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
													${IMG.PROJECT_SUPPORT}/support_480.webp,
													${IMG.PROJECT_SUPPORT}/support_960.webp 2x,
													${IMG.PROJECT_SUPPORT}/support_1440.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 480px)"
                        srcSet={`
												${IMG.PROJECT_SUPPORT}/support_480.jpg,
												${IMG.PROJECT_SUPPORT}/support_960.jpg 2x,
												${IMG.PROJECT_SUPPORT}/support_1440.jpg 3x`}
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
												${IMG.PROJECT_SUPPORT}/support_852.avif,
												${IMG.PROJECT_SUPPORT}/support_1704.avif 2x,
												${IMG.PROJECT_SUPPORT}/support_2556.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
												${IMG.PROJECT_SUPPORT}/support_852.webp,
												${IMG.PROJECT_SUPPORT}/support_1704.webp 2x,
												${IMG.PROJECT_SUPPORT}/support_2556.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`
												${IMG.PROJECT_SUPPORT}/support_852.jpg,
												${IMG.PROJECT_SUPPORT}/support_1704.jpg 2x,
												${IMG.PROJECT_SUPPORT}/support_2556.jpg 3x`}
                      />
                      <img
                        src={`${IMG.PROJECT_SUPPORT}/support_2556.jpg`}
                        alt="Discussion of the project at the LaSoft office"
                        width="598"
                        height="426"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div className={styles.terms__text}>
                    <p>The main reasons why you need support are:</p>
                    <ul className={styles.checkMarked}>
                      <li>
                        Code libraries become outdated and depreciated over time
                      </li>
                      <li>Third-party APIs change and sometimes brake</li>
                      <li>
                        All software has issues (sometimes very tricky ones)
                      </li>
                      <li>
                        Not every issue is a fault of the development team
                      </li>
                      <li>
                        You get new ideas for user experience and better ways to
                        make your system work.
                      </li>
                    </ul>
                    <p>
                      All software requires support, and it's good practice to
                      agree and define support terms with your vendor upfront or
                      hire in-house programmers to handle software support on
                      your side. A good budgeting practice is to set aside 20%
                      of the development budget annually for support needs.
                    </p>
                  </div>
                </div>
              </section>

              {/* ------- Experts ------- */}
              <section className={cx(styles.expertise, "section")}>
                <TitleBlock options={TITLES.expertise} />

                <div className={styles.expertise__content}>
                  <ul>
                    {EXPERTISE.map(item => (
                      <li key={item.id}>
                        <div
                          className={cx(
                            styles.containerWithBefore,
                            `${item.icon}`
                          )}
                        >
                          <h3>{item.name}</h3>
                          <ul className={styles.simpleList}>
                            {item.text.map((option, index) => (
                              <li key={index}>{option}</li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </section>

              {/* -------  ------- */}
              <section className={styles.scrollingBackground}>
                <p>
                  <strong>
                    Get the best solutions from our experts to grow up your
                    business
                  </strong>
                </p>
              </section>

              {/* ------- Options of the project support period ------- */}
              <section className={cx(styles.supportLevels, "section")}>
                <TitleBlock options={TITLES.package} />

                <ul className={styles.supportLevelsContent}>
                  {LEVELS.map(item => (
                    <PackageCard key={item.id} item={item} />
                  ))}
                </ul>
              </section>

              {/* ------- Projects with our support ------- */}
              <section className="portfolioChapter__wrapper">
                <div className="portfolioChapter__list">
                  <h2 className={styles.hiddenTitle}>
                    Projects with our support
                  </h2>
                  {projectsList.length > 0 &&
                    projectsList.map(item => (
                      <section
                        className="portfolioChapter__singleProjectSummary"
                        key={item.title}
                      >
                        <div className="portfolioChapter__singleProjectSummary__text">
                          <div className="portfolioChapter__singleProjectSummary__text__description">
                            <h2>
                              {item.externalLink ? (
                                <a
                                  href={item.externalLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.title}
                                </a>
                              ) : item.slug ? (
                                <Link to={`/${item.slug}/`}>{item.title}</Link>
                              ) : (
                                item.title
                              )}
                            </h2>
                            <ul className="tags">
                              <li className="flag">
                                <img
                                  src={`${IMG.INDEX}/${item.flagIconUrl}`}
                                  width="22"
                                  height="16"
                                  alt={`${item.country} flag`}
                                  title={`${item.country} flag`}
                                  loading="lazy"
                                />
                              </li>
                              {item.tagList.map(tag => (
                                <li key={`tag_${tag.replace(/\s/g, "")}`}>
                                  {tag}
                                </li>
                              ))}
                            </ul>
                            <div
                              className="portfolioChapter__singleProjectSummary__visual mobileOnly"
                              data-title={item.title}
                            >
                              <PortfolioImagesContainer item={item} />
                            </div>
                            <div className="info">
                              <p>{item.description}</p>
                            </div>
                            {item.externalLink && (
                              <a
                                href={item.externalLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="portfolioChapter__seeMore"
                              >
                                Discover project
                              </a>
                            )}
                            {item.slug && (
                              <Link
                                to={`/${item.slug}`}
                                className="portfolioChapter__seeMore"
                              >
                                Discover project
                              </Link>
                            )}
                          </div>
                        </div>
                        <div
                          className="portfolioChapter__singleProjectSummary__visual"
                          data-title={item.title}
                        >
                          <PortfolioImagesContainer item={item} />
                        </div>
                        {item.testimonial && (
                          <div className="testimonial">
                            <div className="avatar">
                              <img
                                src={`${IMG.INDEX}/${item.testimonial.avatar}`}
                                width="64"
                                height="64"
                                alt={item.testimonial.name}
                                title={item.testimonial.name}
                                loading="lazy"
                              />
                            </div>
                            <div className="img">
                              <img
                                src={`${IMG.INDEX}/img/qou.svg`}
                                width="20"
                                height="20"
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div className="text">
                              <p>{item.testimonial.paragraph}</p>
                              <strong>{item.testimonial.name}</strong>
                              <p>{item.testimonial.job}</p>
                            </div>
                          </div>
                        )}
                      </section>
                    ))}
                </div>
              </section>
            </article>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default ProjectSupport
